import SignupContainer from "../components/auth/SignupContainer";
import logo from "../assets/images/logo.svg";
import bgImage from "../assets/images/bgImage.png";

export default function SignUp() {
  return (
    <div
      className="flex flex-col w-full h-screen bg-cover bg-center bg-no-repeat bg-fixed items-center justify-center overflow-scroll py-5"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className="w-[200px] mb-[60px]">
        <img src={logo} alt="logo" />
      </div>
      <div className="flex flex-col justify-center">
        <SignupContainer />
      </div>
    </div>
  );
}
