import axios from "axios";
import { getAuthHeader } from "../lib/utils";

export const getScannerListService = () => {
  const url = `/api/v1/scanner/`;
  return axios.get(url, getAuthHeader());
};

export const getScannerAnalysisResultService = async (uuid: string) => {
  const url = `/api/v1/scanner/${uuid}/analysis-results/?limit=1`;
  return axios.get(url, getAuthHeader());
};

export const getScannerSeriesService = async (scanner: string) => {
  const url = `/api/v1/series/?scanner=${scanner}`;
  return axios.get(url, getAuthHeader());
};

export const CreateStudyServiceHandler = (data: any) => {
  const url = `/api/v1/study/`;
  return axios.post(url, data, getAuthHeader());
};
