import { Link, useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle
} from "../ui/card";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import { useAtom } from "jotai";
import { commonAtom } from "../../store/commonAtom";
import { useState } from "react";
import UseAuthHook from "../../hooks/serviceHandler/auth";
import { ClipLoader } from "react-spinners";

export default function ForgotPasswordConatiner() {
  const [common] = useAtom(commonAtom);
  const [username, setUsername] = useState("");

  const navigate = useNavigate();

  const { ForgotPasswordHandler } = UseAuthHook();

  const handleChange = (key: "username", value: string) => {
    setUsername(value)
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const isValid = !!username;
    if (isValid) {
      ForgotPasswordHandler(username);
      navigate("/");
    }
  };

  return (
    <div className="flex flex-col">
      <Card className="min-h-[40vh] lg:w-[35vw] lg:px-10 lg:py-10">
        <CardHeader>
          <CardTitle className="text-2xl text-foreground">Reset your password</CardTitle>
          <p className="text-sm">
            Forgotten your password? Enter your email address below, and we'll email instructions for setting a new one.
          </p>
        </CardHeader>
        <CardContent className="flex flex-col gap-6 lg:gap-9">
          <form className="flex flex-col gap-3 lg:gap-5">
            <div>
              <Label>Email</Label>
              <Input
                className="mt-2"
                placeholder="your@email.com"
                type="email"
                name="username"
                id="username"
                value={username}
                onChange={(e) => handleChange("username", e.target.value)}
              />
            </div>
            <div></div>
            <div className="">
              <Button disabled={!username} className="w-full py-5" onClick={handleSubmit}>
                {common.isLoading ? (
                  <ClipLoader
                    color={"#ffffff"}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  "Reset Password"
                )}
              </Button>
            </div>
          </form>
          <div className="flex justify-between">
            <p className="text-sm text-primary font-medium hover:text-primary/80 transition-all">
              <Link to={component.login.url}>Login</Link>
            </p>
            <p className="text-sm text-primary font-medium hover:text-primary/80 transition-all">
              <Link to={component.signup.url}>Sign Up</Link>
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
