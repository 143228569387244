import { Link, useNavigate, useParams } from "react-router-dom";
import component from "../../constants/urls";
import useLoginValidation from "../../hooks/validation/Login";
import UseAuthHook from "../../hooks/serviceHandler/auth";
import { ClipLoader } from "react-spinners";
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { commonAtom } from "../../store/commonAtom";

export default function LoginContainer() {
  const [common] = useAtom(commonAtom);
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  const { token } = useParams();

  const navigate = useNavigate();

  const { LoginFormValidation } = useLoginValidation();

  const { LoginHandler, ImpersonateHandler } = UseAuthHook();

  useEffect(() => {
    if (token) {
      ImpersonateHandler(token);
      navigate("/");
    }
  });


  const handleChange = (key: "username" | "password", value: string) => {
    setLoginData({ ...loginData, [key]: value });
  };

  const handleLogin = (e: any) => {
    e.preventDefault();
    const isValid = LoginFormValidation(loginData.username, loginData.password);
    if (isValid) {
      LoginHandler(loginData.username, loginData.password);
      navigate("/");
    }
  };

  if (token) {
    return <></>
  }

  return (
    <div className="flex flex-col">
      <Card className="min-h-[40vh] lg:w-[35vw] lg:px-10 lg:py-10">
        <CardHeader>
          <CardTitle className="text-2xl text-foreground">
            Sign in to your account
          </CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col gap-6 lg:gap-9">
          <form className="flex flex-col gap-3 lg:gap-5">
            <div>
              <Label>Email</Label>
              <Input
                className="mt-2"
                placeholder="your@email.com"
                type="email"
                name="username"
                id="username"
                value={loginData.username}
                onChange={(e) => handleChange("username", e.target.value)}
              />
            </div>
            <div>
              <Label>Password</Label>
              <Input
                className="mt-2"
                type="password"
                name="password"
                id="password"
                placeholder="******"
                value={loginData.password}
                onChange={(e) => handleChange("password", e.target.value)}
              />
            </div>
            <div></div>
            <div className="">
              <Button className="w-full py-5" onClick={handleLogin}>
                {common.isLoading ? (
                  <ClipLoader
                    color={"#ffffff"}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  "Login"
                )}
              </Button>
            </div>
          </form>
          <div className="flex justify-between">
            <p className="text-sm text-primary font-medium hover:text-primary/80 transition-all">
              <Link to={component.forgotPassword.url}>Forgot password?</Link>
            </p>
            <p className="text-sm text-primary font-medium hover:text-primary/80 transition-all">
              <Link to={component.signup.url}>Create an account</Link>
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
