import { Route, Routes } from "react-router-dom";
import component from "../constants/urls";
import Page404 from "../components/pageNotFound/page404";
import Dashboard from "../pages/Dashboard";
import Profile from "../pages/Profile";

export default function AuthRoute() {
  return (
    <Routes>
      <Route path={component.home.url} element={<Dashboard />} />
      <Route path={component.dashboard.url} element={<Dashboard />} />
      <Route path={component.profile.url} element={<Profile />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
