import { scanner, scannerAnalysisResult } from "../interface/atom/scanner";
import { atomWithStorage } from "jotai/utils";

export const graphModes = ["snr", "sfnr", "rdc"] as const;
export type graphModeType = (typeof graphModes)[number];

const initialState: {
  scannerList: Array<scanner>;
  scannerAnalysisResults: Array<scannerAnalysisResult>;
  graphMode: graphModeType;
} = {
  scannerList: [],
  scannerAnalysisResults: [],
  graphMode: "sfnr",
};

export const scannerAtom = atomWithStorage("scannerAtom", initialState);
