import { atomWithStorage } from "jotai/utils";

interface UserType {
  fullName: string;
  username: string;
  token: string;
  isAuthenticated: boolean;
}

const initialState = {
  fullName: "",
  username: "",
  token: "",
  isAuthenticated: false,
};

export const userAtom = atomWithStorage<UserType>("userAtom", initialState);
