import {
  getScannerAnalysisResultService,
  getScannerListService,
} from "../../service/dashboard";
import { scanner } from "../../interface/atom/scanner";
import { useAtom } from "jotai";
import { scannerAtom } from "../../store/scannerAtom";
import { commonAtom } from "../../store/commonAtom";

const useDashboardHook = () => {
  const [scannerState, setScannerState] = useAtom(scannerAtom);
  const [commonState, setCommonState] = useAtom(commonAtom);

  const ScannerListServiceHandler = async () => {
    try {
      const res = await getScannerListService();
      const { data } = res;
      if (data?.length) {
        console.log("setting");
        setScannerState({
          ...scannerState,
          scannerList: data,
        });
        let first = data[0] as scanner;
        setCommonState({
          ...commonState,
          currentScanner: first.uuid,
        });
      } else {
        setScannerState({
          ...scannerState,
          scannerList: [],
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const ScannerAnalysisResultsHandler = async (selectedScanner: string) => {
    try {
      const res = await getScannerAnalysisResultService(selectedScanner);
      const { data } = res;
      setScannerState({
        ...scannerState,
        scannerAnalysisResults: data,
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  return {
    ScannerListServiceHandler,
    ScannerAnalysisResultsHandler,
  };
};

export default useDashboardHook;
