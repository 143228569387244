import { Button } from "../ui/button";
import { Label } from "../ui/label";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../ui/sheet";
import { Separator } from "../ui/separator";
import {
  GearIcon,
  Pencil1Icon,
  ArrowTopRightIcon,
} from "@radix-ui/react-icons";
import { useAtom } from "jotai";
import { userAtom } from "../../store/userAtom";

export function SettingsSheet() {
  const [user, setUser] = useAtom(userAtom);

  const handleLogout = () => {
    setUser({
      fullName: "",
      username: "",
      token: "",
      isAuthenticated: false,
    });
  };

  const name = user.username;

  return (
    <Sheet>
      <SheetTrigger asChild>
        <div className="hover:bg-secondary rounded-sm p-2 cursor-pointer">
          <GearIcon width={20} height={20} color="black" />
        </div>
      </SheetTrigger>
      <SheetContent className="flex flex-col justify-between">
        <div>
          <SheetHeader>
            <SheetTitle>Veriflux Profile</SheetTitle>
            <SheetDescription>
              Check your profile information here.
            </SheetDescription>
            <Separator />
          </SheetHeader>
          <div className="grid gap-2 py-4">
            <h1 className="font-medium text-black">Personal Information</h1>
            <div className="flex flex-col">
              <Label className="text-sm opacity-60">Client Name</Label>
              <p className="text-sm text-black capitalize">{name}</p>
            </div>
            <div className="flex flex-col">
              <Label className="text-sm opacity-60">Veriux SN</Label>
              <p className="text-sm text-black">GSP-038VF21324</p>
            </div>
            <Separator className="my-3" />
            <h1 className="font-medium text-black">Billing</h1>
            <div className="flex flex-col">
              <span className="flex justify-between items-end">
                <span>
                  <Label className="text-sm opacity-60">Subscription</Label>
                  <p className="text-sm text-black">Veriux Gold</p>
                </span>
                <ArrowTopRightIcon
                  className="inline hover:bg-secondary rounded-sm p-1.5 cursor-pointer opacity-50 hover:opacity-100"
                  width={28}
                  height={28}
                  color="black"
                />
              </span>
            </div>
            <div className="flex flex-col">
              <Label className="text-sm opacity-60">Account Number</Label>
              <p className="text-sm text-black">VF240011032</p>
            </div>
            <div className="flex flex-col">
              <span className="flex justify-between items-end">
                <span>
                  <Label className="text-sm opacity-60">Billing Email</Label>
                  <p className="text-sm text-black">
                    mriclient@magnettohospital.com
                  </p>
                </span>
                <Pencil1Icon
                  className="inline hover:bg-secondary rounded-sm p-1.5 cursor-pointer opacity-50 hover:opacity-100"
                  width={28}
                  height={28}
                  color="black"
                />
              </span>
            </div>
            <div className="flex flex-col">
              <span className="flex justify-between items-end">
                <span>
                  <Label className="text-sm opacity-60">Payment Method</Label>
                  <p className="text-sm text-black">Visa (4321)</p>
                </span>
                <Pencil1Icon
                  className="inline hover:bg-secondary rounded-sm p-1.5 cursor-pointer opacity-50 hover:opacity-100"
                  width={28}
                  height={28}
                  color="black"
                />
              </span>
            </div>
            <Separator className="my-3" />
          </div>
          <div className="flex flex-col gap-2">
            <Button className="w-full" disabled variant={"outline"}>
              Add More Devices
            </Button>
            <Button className="w-full" disabled variant={"outline"}>
              Request Extensive Analysis
            </Button>
            <Button className="w-full" disabled variant={"outline"}>
              Request Advisory Services
            </Button>
            <Button
              className="w-full"
              onClick={handleLogout}
              variant={"outline"}
            >
              Logout
            </Button>
          </div>
        </div>
        <SheetFooter>
          <div className="flex justify-between w-full">
            <a
              className="text-sm text-black font-medium hover:opacity-50 transition duration-200 ease-in-out hover:underline"
              href="https://help.goldstandardphantoms.com/veriflux/"
            >
              Help
            </a>
            <a
              href="https://help.goldstandardphantoms.com/veriflux/feedback"
              className="text-sm text-black font-medium hover:opacity-50 transition duration-200 ease-in-out hover:underline"
            >
              Feedback
            </a>
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
