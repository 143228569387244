import { Link } from "react-router-dom";
import component from "../../constants/urls";
import { Button } from "../ui/button";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import pageNotFound from "../../assets/images/pageNotFound.svg";
import logo from "../../assets/images/logo.svg";

export default function Page404() {
  return (
    <div className="w-[100vw] h-[100vh] fixed z-50 top-0 left-0 flex gap-10 bg-white">
      <div className="px-[10%] text-center flex flex-col w-1/2 justify-center items-center gap-10">
        <img src={logo} alt="logo" className="w-20" />
        <p className="text-xs text-muted-foreground font-semibold opacity-50">404 error</p>
        <p className="text-black text-5xl font-bold">Something's Missing</p>
        <p className="">You're seeing this page because the URL you entered doesn't exist. Yet.</p>
        <Link to={component.home.url}>
          {" "}
          <Button className="py-5">
            <ArrowLeftIcon className="w-4 h-4 mr-2" />
            Back to Homepage
          </Button>
        </Link>
      </div>
      <div className="w-1/2 flex justify-center items-center bg-muted">
        <img src={pageNotFound} alt="404" className="w-8/12" />
      </div>
    </div>
  );
}
