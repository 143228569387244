import axios from "axios";
import { getAuthHeader } from "../lib/utils";
import { UserLoginCred, RequestPasswordResetDetails, PasswordResetDetails } from "../interface/service/auth";

export const LoginAuthenticationService = async (loginCred: UserLoginCred) => {
  const url = `/api/token/`;
  return axios.post(url, loginCred);
};

export const ForgotPasswordService = async (details: RequestPasswordResetDetails) => {
  const url = `/api/password-reset/`
  return axios.post(url, details)
}

export const ResetPasswordService = async (details: PasswordResetDetails) => {
  const url = `/api/password-reset/confirm/`
  return axios.post(url, details)
}

export const ProfileFetchService = () => {
  const url = `/api/v1/profile`;
  return axios.get(url, getAuthHeader());
};

export const RefreshAuthTokenService = async () => {
  const url = `/api/token/refresh/`;
  return axios.post(url, { refresh: localStorage.getItem("refresh") });
};
