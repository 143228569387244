import { useToast } from "../../components/ui/use-toast";

const useLoginValidation = () => {
  const { toast } = useToast();

  const LoginFormValidation = (username: string, password: string) => {
    if (username?.trim() === "") {
      toast({
        title: "Error",
        description: "Username is required",
        variant: "destructive",
      });
      return false;
    } else if (password?.trim() === "") {
      toast({
        title: "Error",
        description: "Password is required",
        variant: "destructive",
      });
      return false;
    }
    return true;
  };

  return {
    LoginFormValidation,
  };
};

export default useLoginValidation;
