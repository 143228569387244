import React, { useEffect } from "react";
import LongitudinalGraphComponent from "../components/LongitudinalGraphComponent";
import DashboardTable from "../components/dashboardTable/DashboardTable";
import MachineStatusComponent from "../components/MachineStatusComponent";
// import ProgressComponent from "../components/ProgressComponent";
import useDashboardHook from "../hooks/serviceHandler/dashboard";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { Card } from "../components/ui/card";
import { Badge } from "../components/ui/badge";
import { useAtom } from "jotai";
import { commonAtom, DatasetInfo } from "../store/commonAtom";
import { scannerAtom } from "../store/scannerAtom";
import axios, { AxiosError, AxiosResponse } from "axios";
import { getAuthHeader } from "../lib/utils";
import { useToast } from "../components/ui/use-toast";

const viewOne = () => {
  return (
    <div className="flex flex-col gap-5">
      <MachineStatusComponent orientation="horizontal" />
      <LongitudinalGraphComponent />
      <Card className="overflow-scroll lg:overflow-hidden">
        <DashboardTable />
      </Card>
    </div>
  );
};

const viewTwo = () => {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex gap-5">
        <LongitudinalGraphComponent />
        <MachineStatusComponent showScanDates={true} />
      </div>
      <Card className="overflow-scroll lg:overflow-hidden">
        <DashboardTable />
      </Card>
    </div>
  );
};

const viewThree = () => {
  return (
    <div className="flex flex-col gap-5">
      <MachineStatusComponent orientation="horizontal" showScanDates />
      <LongitudinalGraphComponent />
      <Card className="overflow-scroll lg:overflow-hidden">
        <DashboardTable />
      </Card>
    </div>
  );
};

export default function Dashboard() {
  const { ScannerAnalysisResultsHandler } = useDashboardHook();
  const { toast } = useToast();
  const [commonState, setCommonState] = useAtom(commonAtom);
  const { currentScanner, uploads } = commonState;
  const [scannerState] = useAtom(scannerAtom);
  const { scannerList } = scannerState;
  let scanner = scannerList.find((i) => i.uuid === currentScanner);
  console.log('uploads', uploads);

  useEffect(() => {
    // load data on scanner selected
    ScannerAnalysisResultsHandler(currentScanner);

    // schedule refresh every minute
    const intervalId = setInterval(
      () => ScannerAnalysisResultsHandler(currentScanner),
      1000 * 60 // 60 seconds
    );

    // set up cleanup of periodic refresh when another  scanner is selected
    return () => clearInterval(intervalId);
  }, [currentScanner]);

  const checkUploadStatus = async (dataset: DatasetInfo) => {
    while (true) {
      let res: AxiosResponse | undefined;
      const url = `/api/v1/job/${dataset.uuid}`;
      try {
        res = await axios.get(url, getAuthHeader());
      } catch (err) {
        res = (err as AxiosError).response;
      }
      if (!res) break;
      if (res.status === 200) {
        // job done
        toast({
          title: "Dataset Processing Complete",
          variant: "success",
        });
      }
      else if (res.status === 404) {
        // job not found. assume it was completed.
      }
      else if (res.status === 202) {
        //job still in progress

      } else {
        // job failed
        const error = <>{

          ((res.data?.errors || []) as Array<{ detail: string }>).map(({ detail }) => <p>{detail}</p>)
        }</>
        toast({
          title: "Dataset Processing Failed",
          description: error,
          variant: "destructive",
          autodismiss: false,
        });
      }

      if (res.status !== 202) {
        setCommonState({
          ...commonState,
          uploads: commonState.uploads.filter(u => u.uuid !== dataset.uuid),
        });
        break;
      }
      await new Promise(resolve => setTimeout(resolve, 1000 * 60));
    }
  }

  useEffect(() => {
    for (const upload of uploads) {
      // schedule the checking of the upload status
      checkUploadStatus(upload);
    }
  }, [uploads]);

  return (
    <div className="p-4">
      <Tabs defaultValue="view1">
        <div className="flex justify-between items-center mb-4">
          <TabsList>
            <TabsTrigger value="view1">View 1</TabsTrigger>
            <TabsTrigger value="view2">View 2</TabsTrigger>
            <TabsTrigger value="view3">View 3</TabsTrigger>
          </TabsList>
          <TabsContent value="view1">
            <div className="flex gap-4 items-center">
              <Badge variant="secondary" className="h-7">
                <span className="inline-block h-1.5 w-1.5 bg-green-500 mr-1.5 rounded-full"></span>
                <p className="text-[13px] mr-2">Last Scan Date:</p>
                <p className="text-[13px] font-normal">
                  {scanner?.latest_report_date
                    ? new Date(scanner?.latest_report_date).toLocaleString()
                    : "Not Available"}
                </p>
              </Badge>
              <Badge variant="secondary" className="h-7">
                <span className="inline-block h-1.5 w-1.5 bg-red-500 mr-1.5 rounded-full"></span>
                <p className="text-[13px] mr-2">Last Logged Error</p>
                <p className="text-[13px] font-normal ">
                  {scanner?.failed_report_date
                    ? new Date(scanner?.failed_report_date).toLocaleString()
                    : "Not Available"}
                </p>
              </Badge>
            </div>
          </TabsContent>
        </div>
        <TabsContent value="view1">{viewOne()}</TabsContent>
        <TabsContent value="view2">{viewTwo()}</TabsContent>
        <TabsContent value="view3">{viewThree()}</TabsContent>
      </Tabs>
    </div>
  );
}
