import { ReactNode } from "react";

interface AppProps {
  children: ReactNode;
}

export default function FeedbackBanner({ children }: AppProps) {
  return (
    <div className="pt-[5vh]">
      <div className="bg-primary text-white fixed h-[5vh] items-center flex justify-center top-0 w-full z-10">
        Veriflux is still in beta. We'd love to hear feedback &
        suggestions&nbsp;
        <a
          href="https://help.goldstandardphantoms.com/veriflux/feedback"
          className="underline"
        >
          here ↗
        </a>
      </div>
      {children}
    </div>
  );
}
