import { Button } from "../../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";
import Upload from "./Upload";

export default function UploadModal() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="w-full py-7">Upload Data</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Upload</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <Upload />
      </DialogContent>
    </Dialog>
  );
}
