import "./footer.css";

export default function Footer() {
  return (
    <>
      <div className="footerContainer">
        <div>
          <p className="copyright-text">
            &copy; Copyright 2023 &#64; Veriflux. All Rights Reserved{" "}
          </p>
        </div>
        <div className="flex gap-5 items-center privacy-policy-text">
          <a href="https://help.goldstandardphantoms.com/veriflux/terms-and-conditions">
            Terms and Conditions
          </a>
          <a href="https://help.goldstandardphantoms.com/veriflux/privacy-policy">
            Privacy Policy
          </a>
          <a href="https://help.goldstandardphantoms.com/veriflux/feedback">
            Feedback
          </a>
          <a href="https://help.goldstandardphantoms.com/veriflux/">Help</a>
        </div>
      </div>
    </>
  );
}
