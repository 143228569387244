import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

console.log("This is called first");
const buildRef = process.env.REACT_APP_BUILD_REF;
const storeRef = localStorage.getItem("storeRef");
if (buildRef !== storeRef) {
  console.log("Clearing localStorage");
  localStorage.clear();
}

if (buildRef) {
  console.log("Stamping localStorage with buildRef", buildRef);
  localStorage.setItem("storeRef", buildRef);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
reportWebVitals();
