export const PHONE_REGEX = /^[6-9]\d{9}$/;
export const NAME_REGEX = /^[a-zA-Z\s'-]{1,50}$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[^\s]{8,}$/;
export const NUMBER_REGEX = /^\d+$/;
export const NUMBER_ONLY_REGEX = /^[0-9]+$/;
export const errorMessage = {
  required: "This field is required",
  mobileNumber: "Enter a valid mobile number",
  name: "Enter valid name",
  email: "Enter valid email",
  password: "Password must be 8 character",
};

export const color = {
  chartBlue: "#3B82F6",
  chartYellow: "#F4BD50",
  chartRed: "#ef6262",
  chartGreen: "#22c55e",
};
