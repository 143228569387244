import Header from "../components/header/Header";
import AuthRoute from "./AuthRoute";
import UnAuthRoute from "./UnAuthRoute";
import Footer from "../components/footer/Footer";
import Sidebar from "../components/sidebar/Sidebar";
import { useAtom } from "jotai";
import { userAtom } from "../store/userAtom";

export default function Navigation() {
  const [user] = useAtom(userAtom);
  return (
    <>
      {user.isAuthenticated ? (
        <>
          <div className="flex">
            <div className="w-[18vw] h-[95vh] fixed">
              <Sidebar />
            </div>
            <div className="pl-[18vw] w-full">
              <div className="w-full pt-7 pb-4 px-7">
                <Header />
                <AuthRoute />
                <Footer />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <UnAuthRoute />
        </>
      )}
    </>
  );
}
