import { type DataTableRow, columns } from "./Columns";
import { DataTable } from "./DataTable";
import { useAtom } from "jotai";
import { commonAtom } from "../../store/commonAtom";
import { scannerAtom } from "../../store/scannerAtom";

export default function DashboardTable() {
  const [commonState] = useAtom(commonAtom);
  const { currentScanner } = commonState;
  const [scannerState] = useAtom(scannerAtom);
  const { scannerAnalysisResults, scannerList } = scannerState;
  console.log("*****", scannerAnalysisResults, scannerList);
  const selectedScanner = scannerList.find((s) => s.uuid === currentScanner);

  const data = (scannerAnalysisResults.at(-1)?.metadata || []).sort((a, b) =>
    a.scan_date.localeCompare(b.scan_date)
  );

  return (
    <div className="container mx-auto py-10">
      <DataTable columns={columns} data={data} />
    </div>
  );
}
