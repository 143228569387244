import { useLocation } from "react-router-dom";
import component from "../../constants/urls";
import { SettingsSheet } from "../common/SettingsSheet";

export default function Header() {
  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      <div>
        <div className="h-[62px] p-[24px] flex flex-end items-center">
          <div className="w-full flex justify-between gap-[20px] items-center">
            <div className="flex items-center gap-5 font-semibold text-3xl text-black">
              {pathname === component.home.url && "Dashboard"}
              {pathname === component.profile.url && "Profile"}
              {pathname === component.dashboard.url && "Dashboard"}
            </div>
            <div className="flex items-center gap-3">
              <div>
                <SettingsSheet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
