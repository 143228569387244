import { Card } from "./ui/card";
import { Badge } from "./ui/badge";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./ui/hover-card";
import { cn } from "../lib/utils";
import { useAtom } from "jotai";
import { scannerAtom } from "../store/scannerAtom";
import { commonAtom } from "../store/commonAtom";

export default function MachineStatusComponent({
  orientation,
  showScanDates,
}: {
  orientation?: "vertical" | "horizontal";
  showScanDates?: boolean;
}) {
  const [scannerState] = useAtom(scannerAtom);
  const { scannerList, scannerAnalysisResults } = scannerState;
  const [commonState] = useAtom(commonAtom);
  const { currentScanner } = commonState;
  let scanner = scannerList.find((i) => i.uuid === currentScanner);
  const analysisResults = scannerAnalysisResults.at(-1);
  return (
    <Card className="p-4">
      {showScanDates && (
        <>
          <div className={"flex-col"}>
            <Badge variant="secondary" className="py-1.5 mb-4 block">
              <span className="inline-block h-1.5 w-1.5 bg-green-500 mr-1.5 rounded-full"></span>
              <p className="text-[13px] inline mr-2">Last Scan Date:</p>
              <p className="text-[13px] inline font-normal">
                {scanner?.latest_report_date
                  ? new Date(scanner?.latest_report_date).toLocaleString()
                  : "Not Available"}
              </p>
            </Badge>
            <Badge variant="secondary" className="py-1.5 mb-4 block">
              <span className="inline-block h-1.5 w-1.5 bg-red-500 mr-1.5 rounded-full"></span>
              <p className="text-[13px] inline mr-2">Last Logged Error:</p>
              <p className="text-[13px] inline font-normal ">
                {scanner?.failed_report_date
                  ? new Date(scanner?.failed_report_date).toLocaleString()
                  : "Not Available"}
              </p>
            </Badge>
          </div>
        </>
      )}
      <div
        className={
          "grid gap-1 p-1 " +
          (orientation === "horizontal"
            ? "grid-rows-1 grid-flow-col overflow-x-auto w-100"
            : "grid-cols-10")
        }
      >
        {(analysisResults?.metadata || [])
          .sort((a, b) => a.scan_date.localeCompare(b.scan_date))
          .map((entry) => {
            let status: string = entry.stability_status || "indeterminate";
            return (
              <HoverCard>
                <HoverCardTrigger>
                  <div
                    className={
                      "h-[20px] w-[20px] rounded-sm border-2 cursor-help " +
                      (status === "indeterminate"
                        ? "bg-gray-300 border-gray-400"
                        : "") +
                      (status === "unstable"
                        ? "bg-red-400 border-red-500"
                        : "") +
                      (status === "stable"
                        ? "bg-green-400 border-green-500"
                        : "")
                    }
                  ></div>
                </HoverCardTrigger>
                <HoverCardContent className="flex gap-2">
                  <span
                    className={cn(
                      "min-h-5 w-1 rounded-lg",
                      entry.stability_status === "stable"
                        ? "bg-green-400"
                        : entry.stability_status === "unstable"
                          ? "bg-red-400"
                          : "bg-blue-400",
                    )}
                  />
                  <div className="flex flex-col gap-1">
                    <span className="col-span-2 text-xs opacity-70 mb-1">
                      {new Date(entry.scan_date).toLocaleString()}
                    </span>
                    <div className="flex items-end gap-0.5">
                      <span className="font-bold text-base">
                        {entry.rdc.toFixed(3)}
                      </span>
                      <span className="text-[0.70rem] uppercase text-muted-foreground mb-[1px]">
                        RDC
                      </span>
                    </div>
                    <div className="flex items-end gap-1">
                      <span className="font-bold text-base">
                        {entry.result.snr.toFixed(3)}
                      </span>
                      <span className="text-[0.70rem] uppercase text-muted-foreground mb-[1px]">
                        SNR
                      </span>
                    </div>
                    <div className="flex items-end gap-1">
                      <span className="font-bold text-base">
                        {entry.result.sfnr.toFixed(3)}
                      </span>
                      <span className="text-[0.70rem] uppercase text-muted-foreground mb-[1px]">
                        SFNR
                      </span>
                    </div>
                  </div>
                </HoverCardContent>
              </HoverCard>
            );
          })}
        {/*
         * We want to show placeholders for future datasets when there are less than 30 datasets.
         * Using an array sized to the difference between the number of datasets and 30, we can
         * generate the appropriate number of placeholders.
         *
         * When there are more than 30 datasets however, we want no placeholders shown. In this
         * case, the difference between the number of datasets and 30 is negative. Taking the max
         * of this difference and 0, we can generate the appropriate number of placeholders.
         *
         * This fixes a bug where datasets larger than 30 were not accounted for, crashing the
         * page.
         */}
        {[Math.max(40 - (analysisResults?.metadata || [])?.length), 0]
          .fill(1)
          .map((_) => (
            <div className="h-[20px] w-[20px] bg-gray-100 rounded-sm border-2"></div>
          ))}
      </div>
    </Card>
  );
}
