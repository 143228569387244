import React, { useState, useCallback } from "react";
import { Alert, AlertTitle, AlertDescription } from "../../ui/alert";
import { CheckCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import uploadIcon from "../../../assets/images/upload.svg";
import folderIcon from "../../../assets/images/folderIcon.svg";
import { getAuthHeader } from "../../../lib/utils";
import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import { commonAtom } from "../../../store/commonAtom";
import { useAtom } from "jotai";
import { useDropzone } from 'react-dropzone'

export default function Upload() {
  const [file, setFile] = useState<File>();
  const [connected, setConnected] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [errorDetails, setErrorDetails] = useState<AxiosError>();
  const [common, setCommon] = useAtom(commonAtom);

  const UploadFile = (e: React.FormEvent) => {
    console.log("Handling File Selection");
    const files = (e.target as HTMLInputElement).files;
    console.log(files, files?.length);
    if (files && files.length > 0) {
      console.log("Setting file!");
      setFile(files[0]);
      uploadFile(files[0]);
    }
  };

  const setUploadDetails = (details: any) => {
    setCommon({
      ...common,
      uploads: [...common.uploads, details],
    });
  };

  const uploadFile = async (file: File) => {
    console.log("Uploading Dicom File");
    const formData = new FormData();
    formData.append("archive", file);
    console.log("Prepared Data to upload");
    const url = `/api/v1/dataset/`;
    const config: AxiosRequestConfig<FormData> = {
      headers: {
        ...getAuthHeader().headers,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (event) => {
        console.log("Progress Event Received", event);
        if ((event.progress || 0) > 0.1 && !connected) {
          setConnected(true);
        }
        setProgress((event.progress || 0) * 100);
        setUploaded(event.loaded === event.total);
      },
    };
    console.log("Prepared upload request configuration");
    let response: AxiosResponse | undefined;
    try {
      console.log("Beginning file upload");
      response = await axios.post(url, formData, config);
      if (response?.data?.uuid) {
        setUploadDetails(response.data)
      }
      console.log("Successfully uploaded file!", response);
    } catch (error) {
      console.log("Error encountered uploading file", error);
      response = (error as AxiosError)?.response;
      setErrorDetails(error as AxiosError);
      console.log("Failed uploading file!", response, error);
    }

    setProcessed((response?.status || 700) < 300);
    setCompleted(true);
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    if (acceptedFiles && acceptedFiles.length) {
      setFile(acceptedFiles[0]);
      uploadFile(acceptedFiles[0]);
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      {!file ? (
        <div>
          <div {...getRootProps()} className="flex flex-col items-center cursor-pointer" onChange={UploadFile}>
            <input
              {...getInputProps()}
              type="file"
              id="uploadFile"
              name="myfile"
              accept="application/zip,application/gzip,application/x-7z-compressed,.zip,.tar.gz,.tgz,.7z"
              hidden
            />
            <img src={uploadIcon} alt="upload" />
            <p>
              Drag your files here or{" "}
              <span className="font-medium">Browse for files</span>
            </p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-10">
          <div className="flex gap-5 items-center">
            <img src={folderIcon} alt="folder" />
            <div className="grow flex flex-col gap-2">
              <p>{file.name}</p>
              <div className="flex gap-2">
                <div className="grow">
                  <p className="detail">Progress</p>
                  <div className="flex gap-2 items-center">
                    <p className="text-sm mt-1.5">Uploading</p>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 items-left">
                    <div
                      className={
                        ((!connected &&
                          !completed &&
                          "animate-progress w-full h-full origin-left ") ||
                          "") +
                        ((!completed && "bg-blue-600") || "") +
                        ((completed && uploaded && "bg-green-600") || "") +
                        ((completed && !uploaded && "bg-red-600") || "") +
                        ` h-1.5 rounded-full`
                      }
                      style={{
                        width: `${!connected || completed ? "100" : progress}%`,
                      }}
                    ></div>
                  </div>
                  <div className="hidden flex gap-2 items-center">
                    <p className="text-sm mt-1.5">Processing</p>
                  </div>
                  <div className="hidden w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 overflow-hidden">
                    <div
                      className={
                        ((!completed &&
                          uploaded &&
                          "animate-progress bg-blue-600") ||
                          "") +
                        ((completed &&
                          uploaded &&
                          processed &&
                          "bg-green-600") ||
                          "") +
                        ((completed &&
                          uploaded &&
                          !processed &&
                          "bg-red-600") ||
                          "") +
                        ` h-1.5 rounded-full w-full h-full origin-left`
                      }
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {completed && (
            <Alert variant={!processed ? "destructive" : "default"}>
              {processed ? (
                <CheckCircledIcon className="h-4 w-4" />
              ) : (
                <CrossCircledIcon className="h-4 w-4" />
              )}
              <AlertTitle>{processed ? "Success" : "Error"}</AlertTitle>
              <AlertDescription>
                {processed
                  ? "Your file has been successfully uploaded. The dashboard will be updated once analysis completes"
                  : "There was an error uploading your file. Please try again or contact support."}
                {errorDetails?.response?.data ? (
                  (
                    ((errorDetails?.response?.data as any)?.errors ||
                      []) as Array<{
                        detail: string;
                      }>
                  ).map(({ detail }) => <p>{detail}</p>)
                ) : errorDetails?.request ? (
                  <></>
                ) : (
                  <></>
                )}
              </AlertDescription>
            </Alert>
          )}
        </div>
      )}
    </>
  );
}
