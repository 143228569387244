import React from "react";
import { BrowserRouter } from "react-router-dom";
import Navigation from "./routes/Navigation";
import { refreshAuthToken } from "./hooks/serviceHandler/auth";
import { Provider as JotaiProvider } from "jotai";
import { Toaster } from "./components/ui/toaster";
import FeedbackBanner from "./components/common/FeedbackBanner";

function App() {
  React.useEffect(() => {
    refreshAuthToken();
  }, []);
  return (
    <FeedbackBanner>
      <BrowserRouter>
        <JotaiProvider>
          <Navigation />
          <Toaster />
        </JotaiProvider>
      </BrowserRouter>
    </FeedbackBanner>
  );
}

export default App;
