"use client"

import { ColumnDef } from "@tanstack/react-table"
import { CaretSortIcon, CaretUpIcon, CaretDownIcon } from "@radix-ui/react-icons"

// TODO: Might want to refactor all these types into their own file. Also, discuss with team if any are optional / nullable

type Result = {
    central_roi_stats?: CentralRoiStats
    sfnr: number
    snr: number
    weisskoff_data?: WeisskoffData
}
type CentralRoiStats = {
    mean: number
    standard_deviation: number
}
type SingleRoiStats = {
    mean: number
    standard_deviation: number
    coefficient_of_variation: number
    roi_size: number
}
type WeisskoffData = {
    max_roi_size: number
    radius_of_decorrelation: number
    single_roi_stats: SingleRoiStats[]
}

export type DataTableRow = {
    protocol: string
    rdc: number
    result: Result
    scan_date: string
    series_analysis: string
    series_description: string
    stability_status: string
    study_description: string
    when: string
}

// header: ({ column }) => {
//     return (
//       <Button
//         variant="ghost"
//         onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
//       >
//         Email
//         <ArrowUpDown className="ml-2 h-4 w-4" />
//       </Button>
//     )
//   },

export const columns: ColumnDef<DataTableRow>[] = [
  {
    accessorKey: "study_description",
    header: ({ column }) => (
        <span
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        className="flex items-center cursor-pointer mr-3"
        >
            Study
            {column.getIsSorted() ?
                column.getIsSorted() === "asc" ? <CaretUpIcon className="ml-2 h-4 w-4" /> : <CaretDownIcon className="ml-2 h-4 w-4" />
                :
                <CaretSortIcon className="ml-2 h-4 w-4 opacity-40" />
            }
        </span>
    ),
  },
  {
    accessorKey: "series_description",
    header: ({ column }) => (
        <span
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        className="flex items-center cursor-pointer mr-3"
        >
            Protocol
            {column.getIsSorted() ?
                column.getIsSorted() === "asc" ? <CaretUpIcon className="ml-2 h-4 w-4" /> : <CaretDownIcon className="ml-2 h-4 w-4" />
                :
                <CaretSortIcon className="ml-2 h-4 w-4 opacity-40" />
            }
        </span>
    ),
  },
  {
    accessorKey: "rdc",
    header: ({ column }) => (
        <span
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        className="flex items-center cursor-pointer mr-3"
        >
            RDC
            {column.getIsSorted() ?
                column.getIsSorted() === "asc" ? <CaretUpIcon className="ml-2 h-4 w-4" /> : <CaretDownIcon className="ml-2 h-4 w-4" />
                :
                <CaretSortIcon className="ml-2 h-4 w-4 opacity-40" />
            }
        </span>
    ),
    cell: ({ row }) => {
        const roundedRdc = row.original.rdc.toPrecision(3)
        return <span>{roundedRdc}</span>
    }
  },
  {
    accessorKey: "result.snr",
    header: ({ column }) => (
        <span
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        className="flex items-center cursor-pointer mr-3"
        >
            SNR
            {column.getIsSorted() ?
                column.getIsSorted() === "asc" ? <CaretUpIcon className="ml-2 h-4 w-4" /> : <CaretDownIcon className="ml-2 h-4 w-4" />
                :
                <CaretSortIcon className="ml-2 h-4 w-4 opacity-40" />
            }
        </span>
    ),
    cell: ({ row }) => {
        const roundedSnr = row.original.result.snr.toPrecision(3)
        return <span>{roundedSnr}</span>
    }
  },
  {
    accessorKey: "result.sfnr",
    header: ({ column }) => (
        <span
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        className="flex items-center cursor-pointer mr-3"
        >
            SFNR
            {column.getIsSorted() ?
                column.getIsSorted() === "asc" ? <CaretUpIcon className="ml-2 h-4 w-4" /> : <CaretDownIcon className="ml-2 h-4 w-4" />
                :
                <CaretSortIcon className="ml-2 h-4 w-4 opacity-40" />
            }
        </span>
    ),
    cell: ({ row }) => {
        const roundedSfnr = row.original.result.sfnr.toPrecision(3)
        return <span>{roundedSfnr}</span>
    }
  },
  {
    accessorKey: "stability_status",
    header: ({ column }) => (
        <span
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="flex items-center cursor-pointer mr-3"
        >
            Stability
            {column.getIsSorted() ?
                column.getIsSorted() === "asc" ? <CaretUpIcon className="ml-2 h-4 w-4" /> : <CaretDownIcon className="ml-2 h-4 w-4" />
                :
                <CaretSortIcon className="ml-2 h-4 w-4 opacity-40" />
            }
        </span>
    ),
  },
  {
    accessorKey: "scan_date",
    header: ({ column }) => (
        <span
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        className="flex items-center cursor-pointer mr-2 min-w-fit"
        >
            Date
            {column.getIsSorted() ?
                column.getIsSorted() === "asc" ? <CaretUpIcon className="ml-2 h-4 w-4" /> : <CaretDownIcon className="ml-2 h-4 w-4" />
                :
                <CaretSortIcon className="ml-2 h-4 w-4 opacity-40" />
            }
        </span>
    ),
    cell: ({ row }) => {
        const date = new Date(row.original.scan_date)
        return <span>{date.toLocaleString(
            "en-GB",
            {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
            }
        )}</span>
    }
  },
]
