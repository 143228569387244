const component = {
  home: { url: "/" },
  login: { url: "/login" },
  signup: { url: "/sign-up" },
  forgotPassword: { url: "/forgot-password" },
  resetPassword: { url: "/reset-password/:token" },
  dashboard: { url: "/dashboard" },
  profile: { url: "/profile" },
  impersonate: { url: "/masquerade/:token" },
};

export default component;
