import { Link, useNavigate, useParams } from "react-router-dom";
import component from "../../constants/urls";
import { ClipLoader } from "react-spinners";
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { useRef, useState } from "react";
import { useAtom } from "jotai";
import { commonAtom } from "../../store/commonAtom";
import UseAuthHook from "../../hooks/serviceHandler/auth";

/**
 * Helper function to escape a string value that is to be used in a regular expression.
 *
 * This is needed in our case to escape user inputs. For instance, when using a value in a
 * password field in the pattern attribute for a'confirm password' field.
 *
 * This implementation is from the [Mozilla Developer Network]
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_expressions#escaping}.
 *
 */
function escapeRegExp(value: string) {
  return value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export default function ResetPasswordContainer() {
  const [common] = useAtom(commonAtom);
  const [password, setPassword] = useState("");
  const { ResetPasswordHandler } = UseAuthHook();
  const { token } = useParams();
  const navigate = useNavigate();
  const handleResetPassword = (e: any) => {
    if (!(e.target as HTMLFormElement).checkValidity()) { return }
    e.preventDefault();
    ResetPasswordHandler(token || '', password);
    navigate("/");
  };
  const passwordConfirmInput = useRef<HTMLInputElement>(null)
  const setPasswordConfirmValidity = (e: any) => {
    const el = passwordConfirmInput.current as HTMLInputElement;
    el.setCustomValidity(el.value === password ? "" : "Passwords must match");
  }
  return (
    <div className="flex flex-col">
      <Card className="min-h-[40vh] lg:w-[35vw] lg:px-10 lg:py-10">
        <CardHeader>
          <CardTitle className="text-2xl text-foreground">
            Update Your Password
          </CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col gap-6 lg:gap-9">
          <form className="flex flex-col gap-3 lg:gap-5" onSubmit={handleResetPassword}>
            <div>
              <Label>Password</Label>
              <Input
                className="mt-2"
                type="password"
                name="password"
                id="password"
                required
                value={password}
                minLength={12}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <Label>Confirm Password</Label>
              <Input
                className="mt-2"
                type="password"
                name="password"
                id="password"
                ref={passwordConfirmInput}
                required
                pattern={escapeRegExp(password)}
                onInvalid={setPasswordConfirmValidity}
              />
            </div>
            <div></div>
            <div className="">
              <Button className="w-full py-5" type="submit" disabled={common.isLoading}>
                {common.isLoading ? (
                  <ClipLoader
                    color={"#ffffff"}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
