import { useEffect } from "react";
import component from "../../constants/urls";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import useDashboardHook from "../../hooks/serviceHandler/dashboard";
import { scanner } from "../../interface/atom/scanner";
import { Card } from "../ui/card";
import { cn } from "../../lib/utils";
import UploadModal from "../common/uploadModal/Modal";
import { useAtom } from "jotai";
import { scannerAtom } from "../../store/scannerAtom";
import { commonAtom } from "../../store/commonAtom";

export default function Sidebar() {
  const navigate = useNavigate();
  const [common, setCommon] = useAtom(commonAtom);
  const [scanners, setScannerState] = useAtom(scannerAtom);
  const { currentScanner } = common;

  const { scannerList } = scanners;

  const { ScannerListServiceHandler } = useDashboardHook();

  const handleRoute = (url: string) => {
    navigate(url);
  };

  const handleScannerSelect = (scanner: scanner) => {
    setCommon({
      ...common,
      currentScanner: scanner.uuid,
    });
  };

  useEffect(() => {
    if (!scannerList.length) {
      ScannerListServiceHandler();
    }
  });
  console.log(scannerList, "scannerList");
  return (
    <>
      <div className="flex flex-col h-full bg-muted">
        <div className="h-[62px] p-[20px] flex justify-center items-center top-0 z-10">
          <img
            src={logo}
            alt="logo"
            className="h-7"
            onClick={() => handleRoute(component.home.url)}
          />
        </div>
        <div className="p-[20px] flex flex-col justify-between h-full">
          <div className="flex flex-col gap-3 flex-grow h-full">
            {scannerList?.map((item: scanner) => (
              <Card
                className={cn(
                  "px-5 py-4 cursor-pointer hover:shadow-slate-400 shadow-sm transition-shadow duration-200 rounded-md",
                  currentScanner === item?.uuid &&
                    "border-solid border-blue-500 border-2"
                )}
                onClick={() => handleScannerSelect(item)}
                key={item?.uuid}
              >
                <div className="flex items-center">
                  <div>
                    <p
                      className={cn(
                        "text-black mb-1.5",
                        currentScanner === item?.uuid && "font-semibold"
                      )}
                    >
                      {item?.name}
                    </p>
                    <p className="text-xs">S/N: {item?.serial_number}</p>
                    <p className="text-xs">
                      {item?.make} {item?.model}
                    </p>
                  </div>
                </div>
              </Card>
            ))}
          </div>
          <ul>
            <li>
              <UploadModal />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
