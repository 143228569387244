import {
  Line,
  Area,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as RechartsToolTip,
} from "recharts";
import { color } from "../lib/constant";
import { scannerAnalysisResult } from "../interface/atom/scanner";
import { Card } from "../components/ui/card";
import { cn } from "../lib/utils";
import { useAtom } from "jotai";
import { scannerAtom, graphModes, graphModeType } from "../store/scannerAtom";
import * as ToggleGroup from "@radix-ui/react-toggle-group";

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
}

const dateFormatter = (date: number) => {
  return (new Date(date)).toLocaleString("en-GB", dateFormatOptions)
};

const getTicks = (start: number, end: number) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diff = endDate.getTime() - startDate.getTime();
  const interval = diff / 7;
  const tickArray = [];
  for (let i = 0; i <= 7; i++) {
    tickArray.push(startDate.getTime() + i * interval);
  }
  return tickArray;
};

const CustomizedDot = (props: any) => {
  const { cx, cy, payload, active } = props;
  return (
    <svg
      x={cx - 7}
      y={cy - 7}
      width={14}
      height={14}
      viewBox="0 0 1024 1024"
      strokeWidth="3"
    >
      <circle
        cx="512"
        cy="512"
        r={active ? "400" : "300"}
        fill={
          !active
            ? "#FFF"
            : payload.stability_status === "stable"
              ? color.chartGreen
              : payload.stability_status === "unstable"
                ? color.chartRed
                : color.chartBlue
        }
        strokeWidth="180"
        stroke={
          active
            ? "#FFF"
            : payload.stability_status === "stable"
              ? color.chartGreen
              : payload.stability_status === "unstable"
                ? color.chartRed
                : color.chartBlue
        }
      />
    </svg>
  );
};

function LongitudinalGraphComponent() {
  const [scannerState, setScannerState] = useAtom(scannerAtom);
  const { scannerAnalysisResults, graphMode } = scannerState;

  const analysisResults: scannerAnalysisResult | undefined =
    scannerAnalysisResults.at(-1);

  let entries = (analysisResults?.metadata || [])
    .sort((a, b) => a.scan_date.localeCompare(b.scan_date))
    .map((entry) => {
      return {
        ...entry,
        when: Date.parse(entry.scan_date),
        snr: entry.result.snr,
        sfnr: entry.result.sfnr,
      };
    });
  console.log("entries", entries);

  let mean =
    entries.map((i) => i[graphMode]).reduce((a, b) => a + b, 0) /
    entries.length;
  console.log(`Calculated mean ${mean}`);
  let sd = Math.sqrt(
    entries.map((i) => i[graphMode] - mean).reduce((a, b) => a + b ** 2, 0) /
      entries.length,
  );
  let confidence_delta = 1.96 * sd;
  let pos_confidence = mean + confidence_delta;
  let neg_confidence = mean - confidence_delta;
  let stats = Array.from(entries).map((i) => ({
    when: i.when,
    value: i[graphMode],
    mean: mean,
    pos: pos_confidence,
    neg: neg_confidence,
    //for the stacked area chart, we need the deltas
    mean_diff: mean - neg_confidence,
    pos_diff: pos_confidence - mean,
  }));
  let dateToDataset = Object.fromEntries(
    Array.from(entries).map((e) => [e.when, e]),
  );
  console.log("stats", stats);
  console.log("reverse-map", dateToDataset);

  return (
    <Card className="grow p-5">
      <div className="flex flex-col items-center">
        <div className="text-lg font-medium text-black">
          Scanner Stability Analysis
        </div>
        <ToggleGroup.Root
          value={graphMode}
          onValueChange={(value) => {
            if (value)
              setScannerState({
                ...scannerState,
                graphMode: value as graphModeType,
              });
          }}
          className="inline-flex rounded-md border gap-3"
          type="single"
        >
          {graphModes.map((mode) => (
            <ToggleGroup.Item
              value={mode}
              key={mode}
              className="data-[state=on]:font-bold"
            >
              {mode.toUpperCase()}
            </ToggleGroup.Item>
          ))}
        </ToggleGroup.Root>

        {entries.length === 0 ? (
          <div className="text-center text-muted-foreground mt-4">
            No data available
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              data={stats}
              margin={{
                top: 5,
                right: 35,
                left: 35,
                bottom: 0,
              }}
            >
              <YAxis
                className="text-xs"
                dataKey="value"
                type="number"
                // tick={false}
                label={graphMode.toUpperCase()}
                domain={[
                  (dataMin: number) =>
                    (dataMin - confidence_delta * 1.1).toFixed(1),
                  (dataMax: number) =>
                    (dataMax + confidence_delta * 1.1).toFixed(1),
                ]}
                // width={0}
              />
              <XAxis
                className="text-xs"
                dataKey="when"
                tickFormatter={dateFormatter}
                type="number"
                scale="time"
                minTickGap={5}
                interval={0}
                ticks={getTicks(
                  Date.parse(entries[0].scan_date),
                  Date.parse(entries[entries.length - 1].scan_date),
                )}
                domain={["auto", "auto"]}
              />
              <RechartsToolTip
                content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="rounded-lg border bg-background p-2 shadow-sm flex gap-2">
                        <span
                          className={cn(
                            "min-h-10 flex-grow w-1 rounded-lg",
                            payload[0].payload.stability_status === "stable"
                              ? "bg-green-400"
                              : payload[0].payload.stability_status ===
                                  "unstable"
                                ? "bg-red-400"
                                : "bg-blue-400",
                          )}
                        />
                        <div>
                          <div className="grid grid-cols-2 gap-1.5">
                            <span className="col-span-2 text-xs opacity-70">
                              {dateFormatter(payload[0].payload.when)}
                            </span>
                            {payload
                              .filter((i) => i.name === "value")
                              .map((e) => dateToDataset[e.payload?.when])
                              .flatMap((entry) =>
                                graphModes.map((mode) => ({ mode, ...entry })),
                              )
                              .map((entry, index) => (
                                <div
                                  className="flex items-end gap-1"
                                  key={index}
                                >
                                  <span className="font-bold text-base">
                                    {Number(entry[entry.mode]).toFixed(3)}
                                  </span>
                                  <span className="text-[0.70rem] uppercase text-muted-foreground mb-[1px]">
                                    {entry.mode.toUpperCase()}
                                  </span>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Line
                type="monotone"
                // dataKey="rdc"
                data={stats}
                dataKey="value"
                stroke={color.chartBlue}
                activeDot={<CustomizedDot active={true} />}
                dot={<CustomizedDot />}
                strokeWidth={0}
              />
              <Area
                type="monotone"
                data={stats}
                dataKey="neg"
                stackId="1"
                stroke={color.chartRed}
                fill="#00000000"
                strokeWidth={2}
              />
              <Area
                type="monotone"
                data={stats}
                dataKey="mean_diff"
                stackId="1"
                stroke={color.chartBlue}
                fill="#77777725"
                strokeWidth={2}
              />
              <Area
                type="monotone"
                data={stats}
                dataKey="pos_diff"
                stackId="1"
                stroke={color.chartRed}
                fill="#ffc55e25"
                strokeWidth={2}
              />
              {/* <Line */}
              {/*   type="monotone" */}
              {/*   // dataKey="rdc" */}
              {/*   data={meanEntries} */}
              {/*   dataKey="mean" */}
              {/*   stroke={color.chartBlue} */}
              {/*   dot={false} */}
              {/*   strokeWidth={2} */}
              {/* /> */}
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
    </Card>
  );
}

export default LongitudinalGraphComponent;
