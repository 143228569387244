import { atomWithStorage } from "jotai/utils";

export const DashboardViews = [1, 2, 3] as const;

export interface DatasetInfo {
  // Information about uploaded datasets. Used to monitor processing
  uuid: string;
  archive: string;
  date_created: string;
}

const initialState: CommonStateType = {
  sidebarExpand: false,
  isLoading: false,
  modalIsOpen: false,
  currentScanner: "",
  dashboardView: 1,
  uploads: [],
};

interface CommonStateType {
  sidebarExpand: boolean;
  isLoading: boolean;
  modalIsOpen: boolean;
  currentScanner: string;
  uploads: DatasetInfo[];
  dashboardView: (typeof DashboardViews)[number];
}

export const commonAtom = atomWithStorage<CommonStateType>(
  "commonAtom",
  initialState
);
